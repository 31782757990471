
@use 'variables' as vars;

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

@mixin box-shadow($shadows...) {
  -webkit-box-shadow: $shadows;
  -moz-box-shadow: $shadows;
  box-shadow: $shadows;
}

@mixin linear-gradient($angle, $color) {
  background: $color; /* For browsers that do not support gradients */
}

@mixin transition($transitions...) {
  -webkit-transition: $transitions;
  -moz-transition: $transitions;
  -ms-transition: $transitions;
  -o-transition: $transitions;
  transition: $transitions;
}

@mixin transform($transform...) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin columns($cnt, $gap) {
  -webkit-column-count: $cnt;
  -moz-column-count:    $cnt;
  column-count:         $cnt;

  -webkit-column-gap:   $gap;
  -moz-column-gap:      $gap;
  column-gap:           $gap;
}

@mixin fade($duration: 10000ms) {
  @keyframes fade {
    0% {
      opacity: 0;
      z-index: -1;
    }
    15% {
      opacity: 1;
      z-index: 1;
    }
    85% {
      opacity: 1;
      z-index: 1;
    }
    100% {
      opacity: 0;
      z-index: -1;
    }
  }
  opacity: 0;
  z-index: -1;
  animation: fade 10000ms;
}

@mixin glow($duration: 2000ms) {
  @keyframes glow {
    50% {
      color: vars.$xxxdk-gray;
    }
  }
  animation: glow $duration infinite;
}
