@mixin all() {
  html * {
    .fx-flex-ext-no-grow {
      flex-grow: 0;
    }

    .fx-flex-ext-grow {
      flex-grow: 1;
    }
  }
}
