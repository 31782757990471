@use "mixins" as mix;

/// Generates flex align class selectors
@mixin flex-align-classes {
	*.fx-flex-align--start {
		@include mix.flex-align-start-properties;
	}
	*.fx-flex-align--center {
		@include mix.flex-align-center-properties;
	}
	*.fx-flex-align--end {
		@include mix.flex-align-end-properties;
	}
	*.fx-flex-align--baseline {
		@include mix.flex-align-baseline-properties;
	}
	*.fx-flex-align--stretch {
		@include mix.flex-align-stretch-properties;
	}
}

@mixin flex-align-classes-for-media-sizes {
	@each $name, $_ in mix.$flex-layout-media-queries {
		@include mix.flex-layout-media($name) {
			*.fx-flex-align--start--#{$name} {
				@include mix.flex-align-start-properties;
			}
			*.fx-flex-align--center--#{$name} {
				@include mix.flex-align-center-properties;
			}
			*.fx-flex-align--end--#{$name} {
				@include mix.flex-align-end-properties;
			}
			*.fx-flex-align--baseline--#{$name} {
				@include mix.flex-align-baseline-properties;
			}
			*.fx-flex-align--stretch--#{$name} {
				@include mix.flex-align-stretch-properties;
			}
		}
	}
}
