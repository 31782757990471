@use '@angular/material';

// color scheme
$xxxlt-gray: #f5f5f5;
$xxlt-gray: #f1f1f1;
$xlt-gray: #e5edef;
$lt-gray: #cdcdcd;
$md-gray: #9b9b9b;
$dk-gray: #606060;
$xdk-gray: #4b4b4b;
$xxdk-gray: #3c3c3c;
$xxxdk-gray: #1a1a1a;
$off-white-header: #fafaf8;
$off-white: #fdfdff;
$off-white-upsell: #fafaf8;

$xxlt-orange: #fff0da;
$dk-orange: #F57C00;

$green: #189117;

$xxlt-blue: #f1f7ff;
$xlt-blue: #97abbc;
$lt-blue: #76ade4;
$blue: #4a90e2;
$dk-blue: #20375e;
$xdk-blue: #0f2437;

$red: #cd0a16;
$xlt-red: #ffeeef;

// typography variables
$sans-serif-font: 'Jost', serif;
$sans-serif-font-secondary: 'Source Sans 3', sans-serif;
$mono-font: monospace;

// blogs/faq tile gap
$tile-gap: 10px;

$nav-bar-height-gt-xs: 91px;
$nav-bar-height-xs: 60px;
$nav-bar-banner-height: 26px;
$fixed-bar-height: 68px;
