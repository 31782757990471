@use "mixins" as mix;

/// Generates show/hide class selectors
@mixin show-hide-classes {
	*.hide {
		display: none !important;
	}
	@each $breakpoint, $_ in mix.$flex-layout-media-queries {
		*.show-#{$breakpoint} {
			@include mix.flex-layout-media-not($breakpoint) {
				display: none !important;
			}
		}
		*.hide-#{$breakpoint} {
			@include mix.flex-layout-media($breakpoint) {
				display: none !important;
			}
		}
	}
}
