@use "mixins" as mix;

/// Generates layout align class selectors
@mixin layout-align-classes {
	// justify-content:
	*.fx-align--start-x {
		justify-content: flex-start;
	}
	*.fx-align--end-x {
		justify-content: flex-end;
	}
	*.fx-align--center-x {
		justify-content: center;
	}
	*.fx-align--space-between-x {
		justify-content: space-between;
	}
	*.fx-align--space-around-x {
		justify-content: space-around;
	}
	*.fx-align--space-evenly-x {
		justify-content: space-evenly;
	}

	// align-items:
	*.fx-align--x-start {
		align-items: flex-start;
	}
	*.fx-align--x-end {
		align-items: flex-end;
	}
	*.fx-align--x-center {
		align-items: center;
	}
	*.fx-align--x-baseline {
		align-items: baseline;
	}
	*.fx-align--x-stretch {
		align-items: stretch;
	}

	*[class*="fx-align--"]:not([class*="fx-layout"]) {
		@include mix.flex-row-properties;
	}
}

@mixin layout-align-classes-for-media-sizes {
	@each $name, $_ in mix.$flex-layout-media-queries {
		@include mix.flex-layout-media($name) {
			// justify-content:
			*.fx-align--start-x--#{$name} {
				justify-content: flex-start;
			}
			*.fx-align--end-x--#{$name} {
				justify-content: flex-end;
			}
			*.fx-align--center-x--#{$name} {
				justify-content: center;
			}
			*.fx-align--space-between-x--#{$name} {
				justify-content: space-between;
			}
			*.fx-align--space-around-x--#{$name} {
				justify-content: space-around;
			}
			*.fx-align--space-evenly-x--#{$name} {
				justify-content: space-evenly;
			}

			// align-items:
			*.fx-align--x-start--#{$name} {
				align-items: flex-start;
			}
			*.fx-align--x-end--#{$name} {
				align-items: flex-end;
			}
			*.fx-align--x-center--#{$name} {
				align-items: center;
			}
			*.fx-align--x-baseline--#{$name} {
				align-items: baseline;
			}
			*.fx-align--x-stretch--#{$name} {
				align-items: stretch;
			}
		}
	}
}
