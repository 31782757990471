@use '@angular/material' as mat;
@use 'typography';
@use 'variables' as vars;
@use 'm3-main-theme';
@use 'mixins';
@use "css-fx-layout" as fx;
@use 'fx-flex-ext' as fx-ext;

@include mat.core();

// Adjustments to default behavior
*[hidden] {
  display: none !important;
}

html {
  // Apply the light theme by default
  // Use the default configuration.

  @include mat.core-theme(m3-main-theme.$light-theme);
  @include mat.all-component-themes(m3-main-theme.$light-theme);
  @include mat.typography-hierarchy(m3-main-theme.$light-theme);
  @include mat.badge-color(m3-main-theme.$light-theme, $color-variant: primary);


  // fx-layout with CSS class selectors:
  @include fx.class-selectors(true);
  @include fx.flex-size-class-selectors(5, 100, 5, true);
  @include fx.flex-size-class-selectors-from-list(33 66, true);
  @include fx.gap-class-selectors-from-list(0 4 8 16 32 64, px, true);

  body {
    -webkit-tap-highlight-color: transparent;
    background-color: vars.$off-white;

    .mbnm-input-error {
      border-color: vars.$red;
    }
    .mbnm-input-success {
      border-color: vars.$green;
    }

    // snack bar custom colors
    .mdc-snackbar {
      &.warn {
        background-color: vars.$dk-orange;
      }

      &.success {
        background-color: vars.$green;
      }

      &.error {
        background-color: vars.$red;
      }

      button {
        color: inherit !important;
      }
      .mat-mdc-snackbar-surface {
        background-color: inherit;
      }
    }

    .mdc-text-field {
      padding: 0;
    }

    // Make spinners blue (again, can't use variables for some reason)
    .mat-secondary {
      --mdc-circular-progress-active-indicator-color: #4a90e2;
    }

    .mat-primary {
      --mdc-circular-progress-active-indicator-color: #189117;
    }

    .mat-tertiary {
      --mdc-circular-progress-active-indicator-color: #606060;
    }

    // Checkmark color
    .mdc-checkbox__native-control:checked~.mdc-checkbox__background
    .mdc-checkbox__checkmark-path {
      color: white;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: inherit;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
    }

    .cdk-overlay-container {
      --mat-dialog-headline-padding: 16px 24px;
      --mat-dialog-content-padding: 36px 24px;
      --mat-dialog-with-actions-content-padding: 16px 24px;
      --mat-dialog-actions-padding: 16px 24px;

      --mdc-dialog-container-color: #fdfdff; // for some reason variables don't work here?
      .cdk-overlay-pane {
        flex-direction: column;

        .select-label {
          text-align: center;
          height: 32px;
          line-height: 32px;
          font-size: medium;
          background: #f1f1f1;
          box-shadow: 0 0 3px 1px #9b9b9b;
          color: #4b4b4b;
          box-sizing: border-box;
          width: 100%;
          transform: translateZ(0);

          button {
            width: 100%;
            cursor: pointer;
            font-size: small;
            padding: 2px 8px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            background-color: vars.$blue;
            color: #fff;
            border: none;
            height: 36px;
            border-radius: 0 0 5px 5px;
          }
        }

        .select-message {
          margin: 0 auto;
          text-align: center;
          max-width: 160px;
          font-size: small;
        }
      }
    }

    .mat-mdc-chip:focus {
      outline: none;
      border: 0;
    }


    .mat-mdc-menu-content {
      // make menu color lighter
      background-color: vars.$off-white;
      padding: 8px;

      // space custom trigger indicator from menu text
      .mat-mdc-menu-item-text {
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 24px;
          .mat-icon {
            margin-right: 0;
          }
        }
      }

      // hide built-in trigger indicator
      .mat-mdc-menu-item-submenu-trigger {
        .mat-mdc-menu-submenu-icon  {
          display: none;
        }
      }
    }

    .mat-mdc-paginator-page-size {
      display: none;
    }

    .fraction {
      letter-spacing: -1px;
      word-spacing: 2px;

      &:after {
        letter-spacing: initial;
        padding-left:1px;
        content: '"';
      }
    }

    .mat-option-extra-text {
      color: vars.$md-gray;
      font-style: italic;
      font-size: smaller;
      vertical-align: top;
      padding-left: 10px;

      &.hl {
        color: vars.$blue;
      }
    }
  }
}
img {
  outline: none;
  border: 0;
}

small {
  font-size: x-small;
  display: inline-block;
}

/* style this to fit your needs */
/* and remove [alt] to apply to all images*/
img[alt] {
  position: relative;
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
  }
}

a {
  outline: none;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: inherit;
  }
}

input, select {
  outline: none;
  font-family: vars.$sans-serif-font;

  &.rounded {
    border: 1px solid vars.$xlt-gray;
    border-radius: 3px;
    background: #fff;
    @include mixins.box-shadow(inset 1px 1px 1px 0 rgba(0, 0, 0, 0.5), inset -1px -1px 1px 0 rgba(0, 0, 0, 0.09));
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.standard-sizes-menu {

  .menu-items-wrapper {
    border: 1px solid vars.$lt-gray;
    background-color: white;
    max-height: 240px;
    overflow-y: scroll;
  }

  > table:first-child {
    width: calc(100% - 20px);
  }

  table {
    width: 100%;
    font-size: unset;
    padding: 0;
    display: table;
    line-height: 30px;
    height: 30px;

    th, td {
      width: 33%;
      text-align: center;
    }

    th:nth-child(2), td:nth-child(2) {
      width: 34%;
    }
  }
}

button {
  white-space: nowrap;
  --mdc-filled-button-container-shape: 8px;
  --mdc-outlined-button-container-shape: 8px;
  @include mat.button-color(m3-main-theme.$light-theme, $color-variant: primary);
  @include mat.icon-button-color(m3-main-theme.$light-theme, $color-variant: primary);
  &.mdc-fab:not(:disabled) {
    background: mat.get-theme-color(m3-main-theme.$light-theme, primary);
    color: white;
  }

  &.mat-secondary {
    --mdc-filled-button-container-shape: 8px;

    --mdc-outlined-button-container-shape: 8px;
    --mdc-outlined-button-container-color: transparent;

    @include mat.icon-button-color(m3-main-theme.$light-theme, $color-variant: secondary);
    @include mat.button-color(m3-main-theme.$light-theme, $color-variant: secondary);

    &.mdc-fab:not(:disabled) {
      background: mat.get-theme-color(m3-main-theme.$light-theme, secondary);
      color: white;
    }
    &.mdc-outlined {
      border: white;
      color: white;
    }
  }

  &.mat-tertiary {
    --mdc-filled-button-container-shape: 8px;

    --mdc-outlined-button-container-color: transparent;
    --mdc-outlined-button-container-shape: 8px;
    --mdc-outlined-button-label-text-color: vars.$xxxdk-gray;

    @include mat.button-color(m3-main-theme.$light-theme, $color-variant: tertiary);
    @include mat.icon-button-color(m3-main-theme.$light-theme, $color-variant: tertiary);

    // special because we want our tertiary button to look like an outline
    &.mat-mdc-unelevated-button {
      border: 1px solid vars.$xxxdk-gray;
      --mdc-filled-button-label-text-color: vars.$xxxdk-gray;
    }

    &.mat-mdc-outlined-button {
      border: 1px solid vars.$off-white;
    }

    &.mdc-fab {
      .mat-icon {
        color: vars.$xxxdk-gray;
      }
      border: 1px solid vars.$xxxdk-gray;
    }

    &.mdc-fab:not(:disabled) {
      background: mat.get-theme-color(m3-main-theme.$light-theme, tertiary);
      color: vars.$xxxdk-gray;
      border: 1px solid vars.$xxxdk-gray;
    }
  }

  &.mat-error {
    --mdc-filled-button-container-shape: 8px;
    --mdc-outlined-button-container-shape: 8px;
    @include mat.button-color(m3-main-theme.$light-theme, $color-variant: error);
    @include mat.icon-button-color(m3-main-theme.$light-theme, $color-variant: error);
    &.mdc-fab:not(:disabled) {
      background: mat.get-theme-color(m3-main-theme.$light-theme, error);
      color: white;
    }
  }
}

input.boxed {
  border: 1px solid #9c9c9c;
  height: 25px;
  margin: 8px 0;
}

.text-nowrap {
  white-space: nowrap;
}

.mdc-button__label span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.mat-secondary-subtle {
  &:not(.mat-icon) {
    background-color: vars.$md-gray !important;
    color: #fff !important;
  }
}

.mat-white {
  &:not(.mat-icon) {
    background-color: #fff !important;
    color: vars.$xxdk-gray !important;
  }
}

.success-icon {
  color: green;
}

.failure-icon {
  color: red;
}

.mat-mdc-select-panel {
  .mdc-list-item__primary-text {
    white-space: nowrap !important;
    overflow: visible;
  }
  .mat-pseudo-checkbox-minimal {
    margin-left: 8px;
  }

  &.shipping-options {
    width: 275px !important;
    margin-left: 0px;
  }

  &.shapes {
    width: 250px !important;
  }

  &.sizes {
    background: white;
    position: relative;
    .mat-pseudo-checkbox {
      display: none;
    }

    &.hasExtras {
      .mat-mdc-option {
        padding: 0 35px 0 10px;
      }
    }

    .mat-mdc-option {
      padding: 0 120px 0 10px;
      line-height: calc(3.5em + 4px);
      height: calc(3.5em + 4px);

      &.tall {
        line-height: calc(7em + 8px);
        height: calc(7em + 8px);
      }

      &.not-active-size {
        z-index: 1;
      }

      &.fractions-activator {
        position: absolute;
        left: 48px;
        margin-top: calc(-1.75em - 32px);
        padding: 0;
        width: calc(100% - 48px);
        z-index: 2;
        color: rgba(0, 0, 0, 0.87);

        &:hover {
          background: inherit;
        }

        &.tall {
          margin-top: calc(-3.5em - 64px);
        }
      }
    }

    @-moz-document url-prefix() {
      .mat-mdc-optgroup {
        overflow: hidden;
      }
    }

    .mat-mdc-optgroup {
      display: block;
      max-width: 100%;
      overflow-x: auto;
      transition: opacity 0.5s;
      padding-top: 2px;
      position: absolute;
      margin-top: calc(-3.5em - 4px);
      margin-left: 40px;

      &.tall {
        margin-top: calc(-7em - 8px);
      }

      @-webkit-keyframes reveal {
        0% {
          width: calc(100% - 40px);
        }
        100% {
          width: 0;
        }
      }
      @-moz-keyframes reveal {
        0% {
          width: calc(100% - 40px);
        }
        100% {
          width: 0;
        }
      }
      @-o-keyframes reveal {
        0% {
          width: calc(100% - 40px);
        }
        100% {
          width: 0;
        }
      }
      @keyframes reveal {
        0% {
          width: calc(100% - 40px);
        }
        100% {
          width: 0;
        }
      }

      &:after {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        width: 0;
        height: 100%;
        background: #fff;

        -webkit-animation: reveal 0.5s; /* Safari 4+ */
        -moz-animation: reveal 0.5s; /* Fx 5+ */
        -o-animation: reveal 0.5s; /* Opera 12+ */
        animation: reveal 0.5s; /* IE 10+, Fx 29+ */
      }

      .mat-mdc-optgroup-label {
        display: none;
      }

      .mat-mdc-option {
        font-size: large;
        box-sizing: border-box;

        &.frac {
          display: inline-block;
          padding: 2px 8px;
          font-size: larger;
          line-height: calc(1em + 4px);
          height: calc(1em + 4px);
          min-height: unset;
        }
      }
    }
  }

  &.states {
    .mat-pseudo-checkbox {
      display: none;
    }
  }
}

.mat-mdc-option.qty-options {
  font-family: vars.$sans-serif-font;
  word-spacing: 0.5px;
  padding: 0 15px;
  overflow: visible;

  s {
    font-size: small;
    color: vars.$red;
    padding-right: 3px;
  }

  .green {
    color: vars.$green;
    font-weight: bold;
  }

  .sm {
    font-family: vars.$sans-serif-font-secondary;
    font-size: small;
  }

  sup {
    font-size: x-small;
  }
}

.glowing {
  @include mixins.glow();
}

@include fx-ext.all;
