@use "mixins" as mix;

/// Generates flex class selectors
@mixin flex-classes {
	*.fx-flex {
		@include mix.flex-properties;
	}

	*.fx-flex-auto {
		@include mix.flex-auto-properties;
	}

	*.fx-flex-grow {
		@include mix.flex-grow-properties;
	}

	*.fx-flex-initial {
		@include mix.flex-initial-properties;
	}

	*.fx-flex-none {
		@include mix.flex-none-properties;
	}

	*.fx-flex-nogrow {
		@include mix.flex-nogrow-properties;
	}

	*.fx-flex-noshrink {
		@include mix.flex-noshrink-properties;
	}

	*.fx-flex-fill {
		@include mix.flex-fill-properties;
	}

	*:has(>.fx-flex,>.fx-flex-auto,>.fx-flex-grow,>.fx-flex-initial,>.fx-flex-none,>.fx-flex-nogrow,>.fx-flex-noshrink,>.fx-flex-fill,>[class*="fx-flex--"]):not([class*="fx-layout-"]) {
		@include mix.flex-row-properties;
	}
}

/// Generates flex class selectors for media sizes
@mixin flex-classes-for-media-sizes {
	@each $name, $_ in mix.$flex-layout-media-queries {
		@include mix.flex-layout-media($name) {
			*.fx-flex--#{$name} {
				@include mix.flex-properties;
			}

			*.fx-flex-auto--#{$name} {
				@include mix.flex-auto-properties;
			}
		
			*.fx-flex-grow--#{$name} {
				@include mix.flex-grow-properties;
			}
		
			*.fx-flex-initial--#{$name} {
				@include mix.flex-initial-properties;
			}
		
			*.fx-flex-none--#{$name} {
				@include mix.flex-none-properties;
			}
		
			*.fx-flex-nogrow--#{$name} {
				@include mix.flex-nogrow-properties;
			}
		
			*.fx-flex-noshrink--#{$name} {
				@include mix.flex-noshrink-properties;
			}

			*.fx-flex-fill--#{$name} {
				@include mix.flex-fill-properties;
			}

		}

		*:has(>.fx-flex--#{$name},>.fx-flex-auto--#{$name},>.fx-flex-grow--#{$name},>.fx-flex-initial--#{$name},>.fx-flex-none--#{$name},>.fx-flex-nogrow--#{$name},>.fx-flex-noshrink--#{$name},>.fx-flex-fill--#{$name}):not([class*="fx-layout-"]) {
			@include mix.flex-row-properties;
		}
	}
}
