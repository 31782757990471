@use 'variables' as vars;
@use 'mixins';
@use 'css-fx-layout' as fx;

html {
  font-family: vars.$sans-serif-font;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.6;
  color: vars.$xxdk-gray;
}

body {
  @extend html;
}


h1 {
  font-family: vars.$sans-serif-font;
  font-size: 2rem;
  line-height: 1.94;
  text-align: center;
  font-weight: 600;
  color: vars.$xxdk-gray;
  @include mixins.transition(font-size ease-in .3s);
  margin: 0;

  @include fx.lg {
    font-size: 2.25rem;
  }

  @include fx.gt-lg {
    font-size: 2.5rem;
  }

  @include fx.xs {
    text-align: center;
  }
}

h2 {
  @extend h1;
  text-align: left;
  font-size: 1.5rem;

  .lg & {
    font-size: 1.75rem;
  }

  .xlg & {
    font-size: 2.00rem;
  }

  @include fx.xs {
    text-align: center;
    text-indent: 0;
  }
}

h3 {
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 600;
  font-family: vars.$sans-serif-font;
  color: vars.$dk-gray;
}

h4 {
  font-weight: 600;
}


