@use "mixins" as mix;

/// Generates layout class selectors
@mixin layout-classes {
	// Default classes
	*.fx-layout-row {
		@include mix.flex-row-properties;
	}

	*.fx-layout-column {
		@include mix.flex-column-properties;
	}

	*.fx-layout-reverse {
		&[class*="fx-layout-row"] {
			@include mix.row-reverse-properties;
		}

		&[class*="fx-layout-column"] {
			@include mix.column-reverse-properties;
		}
	}

	*.fx-layout-wrap {
		@include mix.wrap-properties;
	}
}

@mixin layout-classes-for-media-sizes {
	// classes for flex-layout media sizes
	@each $name, $_ in mix.$flex-layout-media-queries {
		@include mix.flex-layout-media($name) {
			*.fx-layout-row--#{$name} {
				@include mix.flex-row-properties
			}

			*.fx-layout-column--#{$name} {
				@include mix.flex-column-properties;
			}

			*.fx-layout-reverse--#{$name} {
				&[class*="fx-layout-row"] {
					@include mix.row-reverse-properties;
				}

				&[class*="fx-layout-column"] {
					@include mix.column-reverse-properties;
				}
			}

			*.fx-layout-wrap--#{$name} {
				@include mix.wrap-properties;
			}
		}
	}
}
